import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

export function classNames(...classes) {
   return classes.filter(Boolean).join(' ')
}

const HomeTwoNavBar = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            {/* <!-- menu-area --> */}
            <div className={classNames(stickyMenu ? "sticky_menu header-padding sticky_navBar_bg" :
                "", "header-menu-area transparrent-header")}>
               <div className="container black-background">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-6">
                        <div className="logo pos-rel">
                           <Link to="/"><img src="img/logo/lungcarelogo.png" alt=""/></Link>
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-8 col-md-6 col-6">
                        <div className="header__menu header-menu-white">
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to="/">Home</Link></li>
                                 <li><Link to="/about">About Us</Link></li>
                                 <li><Link to="/services">Services</Link></li>
                                 <li><Link to="/doctors">Doctors</Link></li>
                                 <li><a target='_blank' href="https://facebook.com/profile.php/?id=100064073920094&name=xhp_nt__fb__action__open_user&ref=sjthelungcare.com"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a target='_blank' href="https://www.youtube.com/@sjthelungcare3744?ref=sjthelungcare.com"><i className="fab fa-youtube"></i></a></li>
                              </ul>
                           </nav>
                        </div>

                        <div onClick={handleShow} className="side-menu-icon d-lg-none text-end">
                           <button className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars text-white"></i> </button>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-2 d-none d-lg-block d-xl-block">
                        <div className="header-right f-right d-flex flex-row">
                           <Link to="/contact" className="primary_btn btn-icon btn-icon-green me-2"><span>+</span>contact us</Link>
                           <a href="tel: 7975242178" className="primary_btn btn-icon btn-icon-white">
                              Click to Call
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeTwoNavBar;