import React from 'react';
import { Link } from 'react-router-dom';
import useGlobalContext from "../../hooks/useGlobalContext";
import VideoPopup from "../../components/VideoPopup/VideoPopup";

const SJHeroSection = () => {
   const values = useGlobalContext();
   const { setIsOpen } = values;
   return (
      <>
         <VideoPopup videoId="nbNB-1mTEW8" />

         <section className="hero-area">
            <div className="hero-slider hero_two_slider">
               <div className="slider-active">
                  <div className="single-slider slider-height slider-height-2 d-flex align-items-center">
                     <div className="container ">
                        <div className="row align-items-center">
                           <div className="col-xl-6 col-lg-6 col-md-10">
                              <div className="hero-text hero-text-2 pt-35 ">
                                 <div className="hero-slider-caption hero-slider-caption-2 ">
                                    <h1 className="hero-sec">Bengaluru's First Respiratory Institute.</h1>
                                    <h2 className="hero-tag pb-3">"Every life matters..."</h2>
                                    {/*<h4 className="hero-tag pb-3">We are here for your care.</h4>*/}
                                    <h4 className="hero-sec">Interventional Pulmonology and Respiratory Emergency Medicine Hospital</h4>
                                 </div>

                                 <div className="hero-slider-btn py-3">
                                    {/*<div className="absolute bottom-0 right-0 m-4">*/}
                                       <Link to="/about" className="primary_btn btn-icon btn-icon-blue">
                                          <span>+</span> about us
                                       </Link>
                                    {/*</div>*/}
                                    <button onClick={() => setIsOpen(true)} className="play-btn popup-video"><i className="fas fa-play"/></button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default SJHeroSection;