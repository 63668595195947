import React from 'react';
import {Link} from "react-router-dom";


const SingleTeam = ({number,image,name,title}) => {
    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="team-box text-center mb-60">
                    <div className="team-thumb h4team-thumb mb-25 pos-rel">
                        <img src={`img/home4/team/team__thumb${image}.png`} alt=""/>
                        {/*<Link className="team-link" to="/doctorDetails">0{number}</Link>*/}
                        {/*<Link className="team-link" to="">0{number}</Link>*/}
                    </div>
                    <div className="team-content h4team-content mb-15">
                        <h3>{name}</h3>
                        <h6>{title}</h6>
                    </div>
                    {/*<div className="h4team-social">*/}
                    {/*    <ul className="list-inline">*/}
                    {/*        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>*/}
                    {/*        <li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
                    {/*        <li><a href="#"><i className="fab fa-behance"></i></a></li>*/}
                    {/*        <li><a href="#"><i className="fab fa-linkedin"></i></a></li>*/}
                    {/*        <li><a href="#"><i className="fab fa-youtube"></i></a></li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
};

const SJTeams = () => {
    return (
        <>
            <section className="team-area pt-180 pb-65">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                            <div className="section-title text-center pos-rel mb-75">
                                <div className="section-icon">
                                    <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text pos-rel">
                                    <h5>Our Team</h5>
                                    <h1>A Professional & Care Provider</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <SingleTeam number={1} image="1" name="Dr Bhavya Shivalingaiah" title="Doctor" />
                        <SingleTeam number={2} image="2" name="Dr Pooja K R" title="Doctor" />
                        <SingleTeam number={3} image="3" name="Dr Vanishree" title="Doctor" />
                        <SingleTeam number={4} image="4" name="Dr Siddappa Gowda" title="Doctor" />
                    </div>
                </div>
            </section>
        </>
    );
};

export default SJTeams;