import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Service = ({ icon, number, title, description }) => {
    return (
        <>
            <div className="col-xl-4 col-lg-3 col-md-3">
                <div className="service-box service-box-2 mb-30 pos-rel">
                    <div className="service-thumb">
                        <img src={`img/services/service-icon-${icon}.png`} alt="" />
                    </div>
                    <div className="service-content service-content-3">
                        <h3><Link to="">{title}</Link></h3>
                        {/* <p>{description}</p> */}
                    </div>
                    {/*<div className="service-number">*/}
                    {/*    <h1 className="service-big-number">{`0${number}`}</h1>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
};

const SJServices = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [accordionOpen, setAccordionOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    const servicesData = [
        { icon: "1", number: "1", title: "OPD Consultations"},
        { icon: "2", number: "2", title: "Pulmonary Function Test(Spirometry)"},
        { icon: "3", number: "3", title: "Day Care"},
        { icon: "4", number: "4", title: "In-Patient Facility"},
        { icon: "5", number: "5", title: "ICU Care"},
        { icon: "6", number: "6", title: "Lung Rehabilitation & Physiotherapy"},
        { icon: "7", number: "7", title: "Laboratory"},
        { icon: "8", number: "8", title: "X-ray & Radiology"},
        { icon: "9", number: "9", title: "Operation Theatre(OT)"},
        { icon: "10", number: "10", title: "General Surgery"},
        { icon: "11", number: "11", title: "Vaccinations"},
        { icon: "12", number: "12", title: "Sleep Study"},
        { icon: "13", number: "13", title: "CPAP;BiPAP;NIV"},
        { icon: "14", number: "14", title: "Emergency & Casualty"},
        { icon: "15", number: "15", title: "Pharmacy"},
        { icon: "16", number: "16", title: "Allergy Skin Test"},
    ];

    return (
        <>
            <section className="servcies-area gray-bg pt-100">
                <div className="container">

                    <div>
                        <div className="section-text pos-rel">
                            <h5>Services</h5>
                            <h1>What We Do For You?</h1>
                        </div>
                        <div className="section-line pos-rel">
                            <img src="img/shape/section-title-line.png" alt="" />
                        </div>
                    </div>
                    {isMobile ? (
                        <div className="row">
                            <button
                                    className="accordion-button main-accordion-button"
                                    type="button"
                                    onClick={toggleAccordion}
                                    aria-expanded={accordionOpen}
                                >
                                    Click here
                                </button>
                            <div className="col-12">
                                {accordionOpen && (
                                    <div className="row">
                                        {servicesData.map((service, index) => (
                                            <Service key={index} {...service} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        // Desktop view content
                        <div className="row">
                            <div className="col-xl-4 offset-xl-4 col-lg-10 offset-lg-1">
                                <div className="section-title text-center pos-rel mb-75">
                                    <div className="section-icon">
                                        <img className="section-back-icon" src="img/section/section-back-icon.png" alt="" />
                                    </div>
                                    {/*<div className="section-text pos-rel">*/}
                                    {/*    <h5>Services</h5>*/}
                                    {/*    <h1>What We Do For You?</h1>*/}
                                    {/*</div>*/}
                                    {/*<div className="section-line pos-rel">*/}
                                    {/*    <img src="img/shape/section-title-line.png" alt="" />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="row">
                                {servicesData.map((service, index) => (
                                    <Service key={index} {...service} />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default SJServices;
