import React from 'react';
import { Link } from 'react-router-dom';
import HomeTwoNavBar from "../HomeTwo/HomeTwoNavBar/HomeTwoNavBar";

const SJFooter = () => {
    return (
        <>
            <footer>
                <div className="footer-top primary-bg pt-115 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="footer-contact-info mb-30">
                                    <div className="emmergency-call fix">
                                        <div className="emmergency-call-icon f-left">
                                            <i className="fas fa-phone"/>
                                        </div>
                                        <div className="emmergency-call-text f-left">
                                            <h6>Emergency number</h6>
                                            <span>+91 7975242178</span>
                                        </div>
                                    </div>
                                    <div className="footer-logo mb-35">
                                        <Link to="/"><img src="img/logo/SJLOGO.png" alt=""/></Link>
                                    </div>
                                    <div className="footer-contact-content mb-25">
                                        <b>Interventional Pulmonology and Respiratory Emergency Medicine Clinic</b>
                                    </div>
                                    <div className="footer-emailing">
                                        <ul>
                                            <li><i className="far fa-envelope"/><b>thelungcare@gmail.com</b></li>
                                            <p><i className="far fa-flag"/><b>  #498,Widia School Bus Stop,<br/>Hesaraghatta main road,<br/>T-Dasarahalli,Bengaluru,<br/>Karnataka 560057</b></p>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                <div className="footer-widget mb-30">
                                    {/*<div className="footer-title">*/}
                                    {/*    <h3>Departments</h3>*/}
                                    {/*</div>*/}
                                    {/*<div className="footer-menu">*/}
                                    {/*    <ul>*/}
                                    {/*        <li><Link to="/">Home</Link></li>*/}
                                    {/*        <li><Link to="/services">Services</Link></li>*/}
                                    {/*        <li><Link to="/doctors">Doctors</Link></li>*/}
                                    {/*        <li><Link to="/aboutus">About Us</Link></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-xl-2 offset-xl-1 col-lg-3 d-md-none d-lg-block">
                                <div className="footer-widget mb-30">
                                    <div className="footer-title">
                                        <h3>Quick Links</h3>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/services">Services</Link></li>
                                            <li><Link to="/doctors">Doctors</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom pt-25 pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="footer-copyright text-center">
                                    <p className="white-color">Copyright SJ THELUNGCARE. All Rights Reserved </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default SJFooter;