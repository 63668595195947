import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ServicesTwoSingleItem from '../../../../components/ServicesTwoSingleItem/ServicesTwoSingleItem';

const ServicesTwoArea = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [accordionOpen, setAccordionOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    return (
        <>
            <section className="servcies-area gray-bg pt-115 pb-90">
                <div className="container">
                    <div className="row">
                        {/*<div className="col-xl-7 col-lg-8 col-md-12">*/}
                            <div className="section-title pos-rel mb-75">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-left" src="img/section/section-back-icon.png" alt="" />
                                </div>
                                <div className="section-text pos-rel">
                                    <h5>SPECIALIZATIONS</h5>
                                    <h1>Advanced Lung Interventions</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt="" />
                                </div>
                            {/*</div>*/}
                        </div>
                    </div>

                    {isMobile && (
                        <div className="row">
                            <div className="col-12">
                                <button
                                    className={`accordion-button main-accordion-button ${accordionOpen ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleAccordion}
                                    aria-expanded={accordionOpen}
                                >
                                    View Services
                                </button>
                                {accordionOpen && (
                                    <div className="accordion-details">
                                        <ServicesTwoSingleItem
                                            image="1"
                                            title="Bronchoscopy"
                                            description="Bronchoscopy is a diagnostic procedure that allows a doctor to examine your airways, including your throat, larynx, trachea, and lower airways (bronchi) using a thin, flexible tube with a light and camera at its end. It helps in diagnosing and treating various respiratory conditions."
                                        />
                                        <ServicesTwoSingleItem
                                            image="2"
                                            title="THORACOSCOPY"
                                            description="Thoracoscopy is a procedure where a rigid camera is introduced in to pleural cavity through the chest wall. This procedure is also called as Pleuroscopy. This procedure can be done under mild sedation but in SJ THE LUNG CARE we prefer to do it under general anesthesia
. This procedure is performed to take biopsy samples for further testing and also break the loculations in complicated pleural effusions. We perform this procedures in the conditions like TB, Bad growth, fluids around the lung when the diagnosis is uncertain. "
                                        />
                                        <ServicesTwoSingleItem
                                            image="3"
                                            title="LUNG DECORTICATION"
                                            description="The process of peeling off of the outermost covering of lung (pleura) is called DECORTICATION. Sometime diseases of pleural causes the thickening of pleural layer and even loculations with in the lung.
 This thickened pleural may compress the lung from externa side and causes its collapse and hampers gas exchange from body. Decortication is done to remove this kind of abnormal pleural under general anesthesia "
                                        />
                                        <ServicesTwoSingleItem
                                            image="4"
                                            title="STENTING"
                                            description="Our airways gets blocked due to the diseases like bad growth or scars following prolonged ventilator support or some abnormalities associated with birth. In such situation we try to make some space and put a small metallic or silicon tube to make the way for breathing. This procedure is called stenting. It is done under general anesthesia"
                                        />
                                        <ServicesTwoSingleItem
                                            image="5"
                                            title="SNARING & DEBULKING"
                                            description="Removal of bad growth inside lung by the means of intervention is called snaring. In the procedure we equip a technique to block the blood vessels while cutting through the bad growth in order to keep the bleeding to minimum."
                                        />
                                        <ServicesTwoSingleItem
                                            image="6"
                                            title="EBUS"
                                            description="Endo Bronchial Ultrasound- EBUS. Certain part of lungs are not accessible through bronchoscopy, like the structures outside the lung and structures which are in deeper part of the lungs.We use this modality where in we pass ultrasounds to capture the images of the targeted structure and do further diagnostic procedures on those regions."
                                        />
                                        <ServicesTwoSingleItem
                                            image="7"
                                            title="CRYO"
                                            description="This technique freezes the underlying tissue which comes in contact with. The frozen chunk is easy to remove and we usually get large sample for testing.This method can also be used for tumor debulking, foreign body removal and dilatation od stenosed area."
                                        />
                                        <ServicesTwoSingleItem
                                            image="8"
                                            title="APC"
                                            description="Argon Plasma Coagulation (APC) – used to stop severe internal bleeding of the lungs. Certain conditions like prolonged intubation, repeated suctioning or infection leads to constant bleeding in small regions of lungs, which is difficult to control. In such cases we use this modality to stop bleeding."
                                        />
                                        {/*<ServicesTwoSingleItem*/}
                                        {/*    image=""*/}
                                        {/*    title="Endobronchial Ultrasound"*/}
                                        {/*    description="Endobronchial ultrasound (EBUS) is a diagnostic procedure that combines bronchoscopy with ultrasound imaging. It allows for the visualization of structures surrounding the airways and is often used for lung cancer staging and diagnosis."*/}
                                        {/*/>*/}
                                        <ServicesTwoSingleItem
                                            image="9"
                                            title="TRANSTHORACIC LUNG BIOPSY"
                                            description="Whenever the disease pathology is near chest wall, we introduced a wide bored needle through chest wall to get a chunk of tissue to establish the diagnosis. This procedure is called Transthoracic Lung Biopsy. This is a Ultrasound or CT guided procedure. Can expect some complications like Bleeding and Pneumothorax therefore it has to be done with full life support backup. "
                                        />
                                        <ServicesTwoSingleItem
                                            image="10"
                                            title="VATS"
                                            description="Video Assisted Thoracoscopic Surgery(VATS) is a surgery done under general anesthesia. Where 3 holes of about 1 cm is made in chest wall and scopes are introduced inside the pleural cavity to treat the diseases of pleura. We do procedures like decortication, stapling, bullectomy and segmentectomy through VATS
. After the procedure two chest tubes are kept in situ for the fluid drainage. This procedure needs minimum of 5 days of hospital stay "
                                        />
                                        <ServicesTwoSingleItem
                                            image="11"
                                            title="SEGMENTECTOMY, LOBECTOMY & PNEUMONECTOMY"
                                            description="Everyone of us have 2 lungs. Right lung has 3 lobe and left has 2. These lobes are further divided in to segments. Removal of entire lung is called Pneumonectomy. Removal of a particular lobe is called Lobectomy. Removal of segments are called Segmentectomy. These procedures are done when there is bad growth, unresolving infection, or  bad scar marks called bronchiectasis which is constantly troubling the individual."
                                        />
                                        <ServicesTwoSingleItem
                                            image="12"
                                            title="PLEURODESIS"
                                            description="It’s a procedure to seal the pleural space. Pleura covers lungs externally and thoracic cavity internally. The space in between is called pleural space. Some times the diseases of pleural cavity causes the accumulation of fluid or air the spaces which is recurrent. In order to avoid recurrent accumulation we seal the space using certain drugs/chemical agents. This procedure is called pleurodesis."
                                        />
                                        <ServicesTwoSingleItem
                                            image="13"
                                            title="FOREIGN BODY"
                                            description="Any objects which are present in our body which are not meant to there are called foreign bodies. Commonly aspirated foreign bodies are pea nuts, small coins, pins, caps, needles etc. We perform procedures to remove foreign bodies inside the lungs. We remove the foreign objects through rigid bronchoscopy and cryo probe "
                                        />
                                        <ServicesTwoSingleItem
                                            image="14"
                                            title="INTERCOASTAL DRAIN"
                                            description="The spaces between two ribs are called intercoastal space. In the diseases of pleura like pleural effusion and pneumothorax we enter the pleural space through this intercoastal space to drain the fluid or air accumulated. This procedure is called intercoastal drainage( ICD). We insert a pipe to the pleural space and get the air/ fluid drain away. The drain will be kept for 2-3 days according to the disease and removed when accumulated fluid is drained out."
                                        />

                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {!isMobile && (
                        <div className="row">
                            <ServicesTwoSingleItem
                                image="1"
                                title="Bronchoscopy"
                                description="Bronchoscopy is a diagnostic procedure that allows a doctor to examine your airways, including your throat, larynx, trachea, and lower airways (bronchi) using a thin, flexible tube with a light and camera at its end. It helps in diagnosing and treating various respiratory conditions."
                            />
                            <ServicesTwoSingleItem
                                image="2"
                                title="THORACOSCOPY"
                                description="Thoracoscopy is a procedure where a rigid camera is introduced in to pleural cavity through the chest wall. This procedure is also called as Pleuroscopy. This procedure can be done under mild sedation but in SJ THE LUNG CARE we prefer to do it under general anesthesia
. This procedure is performed to take biopsy samples for further testing and also break the loculations in complicated pleural effusions. We perform this procedures in the conditions like TB, Bad growth, fluids around the lung when the diagnosis is uncertain. "
                            />
                            <ServicesTwoSingleItem
                                image="3"
                                title="LUNG DECORTICATION"
                                description="The process of peeling off of the outermost covering of lung (pleura) is called DECORTICATION. Sometime diseases of pleural causes the thickening of pleural layer and even loculations with in the lung.
 This thickened pleural may compress the lung from externa side and causes its collapse and hampers gas exchange from body. Decortication is done to remove this kind of abnormal pleural under general anesthesia "
                            />
                            <ServicesTwoSingleItem
                                image="4"
                                title="STENTING"
                                description="Our airways gets blocked due to the diseases like bad growth or scars following prolonged ventilator support or some abnormalities associated with birth. In such situation we try to make some space and put a small metallic or silicon tube to make the way for breathing. This procedure is called stenting. It is done under general anesthesia"
                            />
                            <ServicesTwoSingleItem
                                image="5"
                                title="SNARING & DEBULKING"
                                description="Removal of bad growth inside lung by the means of intervention is called snaring. In the procedure we equip a technique to block the blood vessels while cutting through the bad growth in order to keep the bleeding to minimum."
                            />
                            <ServicesTwoSingleItem
                                image="6"
                                title="EBUS"
                                description="Endo Bronchial Ultrasound- EBUS. Certain part of lungs are not accessible through bronchoscopy, like the structures outside the lung and structures which are in deeper part of the lungs.We use this modality where in we pass ultrasounds to capture the images of the targeted structure and do further diagnostic procedures on those regions."
                            />
                            <ServicesTwoSingleItem
                                image="7"
                                title="CRYO"
                                description="This technique freezes the underlying tissue which comes in contact with. The frozen chunk is easy to remove and we usually get large sample for testing.This method can also be used for tumor debulking, foreign body removal and dilatation od stenosed area."
                            />
                            <ServicesTwoSingleItem
                                image="8"
                                title="APC"
                                description="Argon Plasma Coagulation (APC) – used to stop severe internal bleeding of the lungs. Certain conditions like prolonged intubation, repeated suctioning or infection leads to constant bleeding in small regions of lungs, which is difficult to control. In such cases we use this modality to stop bleeding."
                            />
                            {/*<ServicesTwoSingleItem*/}
                            {/*    image=""*/}
                            {/*    title="Endobronchial Ultrasound"*/}
                            {/*    description="Endobronchial ultrasound (EBUS) is a diagnostic procedure that combines bronchoscopy with ultrasound imaging. It allows for the visualization of structures surrounding the airways and is often used for lung cancer staging and diagnosis."*/}
                            {/*/>*/}
                            <ServicesTwoSingleItem
                                image="9"
                                title="TRANSTHORACIC LUNG BIOPSY"
                                description="Whenever the disease pathology is near chest wall, we introduced a wide bored needle through chest wall to get a chunk of tissue to establish the diagnosis. This procedure is called Transthoracic Lung Biopsy. This is a Ultrasound or CT guided procedure. Can expect some complications like Bleeding and Pneumothorax therefore it has to be done with full life support backup. "
                            />
                            <ServicesTwoSingleItem
                                image="10"
                                title="VATS"
                                description="Video Assisted Thoracoscopic Surgery(VATS) is a surgery done under general anesthesia. Where 3 holes of about 1 cm is made in chest wall and scopes are introduced inside the pleural cavity to treat the diseases of pleura. We do procedures like decortication, stapling, bullectomy and segmentectomy through VATS
. After the procedure two chest tubes are kept in situ for the fluid drainage. This procedure needs minimum of 5 days of hospital stay "
                            />
                            <ServicesTwoSingleItem
                                image="11"
                                title="SEGMENTECTOMY, LOBECTOMY & PNEUMONECTOMY"
                                description="Everyone of us have 2 lungs. Right lung has 3 lobe and left has 2. These lobes are further divided in to segments. Removal of entire lung is called Pneumonectomy. Removal of a particular lobe is called Lobectomy. Removal of segments are called Segmentectomy. These procedures are done when there is bad growth, unresolving infection, or  bad scar marks called bronchiectasis which is constantly troubling the individual."
                            />
                            <ServicesTwoSingleItem
                                image="12"
                                title="PLEURODESIS"
                                description="It’s a procedure to seal the pleural space. Pleura covers lungs externally and thoracic cavity internally. The space in between is called pleural space. Some times the diseases of pleural cavity causes the accumulation of fluid or air the spaces which is recurrent. In order to avoid recurrent accumulation we seal the space using certain drugs/chemical agents. This procedure is called pleurodesis."
                            />
                            <ServicesTwoSingleItem
                                image="13"
                                title="FOREIGN BODY"
                                description="Any objects which are present in our body which are not meant to there are called foreign bodies. Commonly aspirated foreign bodies are pea nuts, small coins, pins, caps, needles etc. We perform procedures to remove foreign bodies inside the lungs. We remove the foreign objects through rigid bronchoscopy and cryo probe "
                            />
                            <ServicesTwoSingleItem
                                image="14"
                                title="INTERCOASTAL DRAIN"
                                description="The spaces between two ribs are called intercoastal space. In the diseases of pleura like pleural effusion and pneumothorax we enter the pleural space through this intercoastal space to drain the fluid or air accumulated. This procedure is called intercoastal drainage( ICD). We insert a pipe to the pleural space and get the air/ fluid drain away. The drain will be kept for 2-3 days according to the disease and removed when accumulated fluid is drained out."
                            />

                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default ServicesTwoArea;
