import React, {useState} from 'react';




const SJAboutArea = () => {



    return (
        <>
            <section className="about-area pt-120 pb-90">
                <div className="background-blur">
                    {/*{selectedService && <div className="blur-overlay"/>}*/}
                    <div className="container">
                        <div className="row">


                           <div className="col-xl-6 col-lg-7">
                              <div className="about-right-side pt-55 mb-30">
                                 <div className="about-title mb-20">
                                    <h3>About Us</h3>
                                    {/*<h1>Short Story About SJ Lung Care.</h1>*/}
                                 </div>
                                 <div className="about-text">
                                    <p>We are the only Full scale Respiratory Center in Bangalore. <br/>25 Oxygen supportive beds, Fully equipped ICU, Facility for all types respiratory interventions <br/>Round the clock Pulmonologists specialized in interventions, Respiratory technicians & other supporting staff who are well versed and well groomed in handling respiratory emergencies.</p>

                                 </div>
                                 <div className="about-author d-flex align-items-center">
                                    {/*<div className="author-ava">*/}
                                    {/*   <img src="img/about/author-ava.png" alt=""/>*/}
                                    {/*</div>*/}
                                    <div className="author-desination">
                                       <h4>Dr. Bhavya Shivalingaiah</h4>
                                       <h6>Consultant Pulmonologist</h6>
                                    </div>
                                 </div>
                              </div>
                           </div>
                            <div className="col-xl-6 col-lg-5 about_left">
                                <div className="about-left-side pos-rel mb-30">
                                    <div className="relative">
                                        <img src="img/about/about-img.jpg" alt="" className="w-full h-auto"/>

                                    </div>

                                    {/*<div className="about-shape">*/}
                                    {/*    <img src="img/about/about-shape.png" alt=""/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SJAboutArea;
