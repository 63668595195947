import React from 'react';
import { Link } from 'react-router-dom';

const SJCta = () => {
	return (
		<>
			<section className="cta-area pos-rel pt-115 pb-120 cta_bg">
				<div className="container">
					<div className="row">
						<div className="col-xl-10 offset-xl-1 col-md-12">
							<div className="cta-text text-center">
								<div className="section-title pos-rel mb-50">
									<div className="section-text section-text-white pos-rel">
										{/*<h5>Stay healthy & strong to enjoy life</h5>*/}
										<h5>Our Philosophy</h5>
										<h3 className="white-color second-white">“Awareness leads to Precautions, Preparations & ultimately Education”</h3>
										<h5 className="white-color">
											Major part of respiratory problems is not knowing enough about it.<br/>
											We are a group of passionate people lead by Dr Bhavya Shivalingaiah who have deep interest,<br/> learning & experience in Respiratory issues. We focus a lot, to “Provide awareness <br/>and Educate the patients & families about their issue” ,<br/> so that they can manage themselves much better, have minimal sufferings<br/> due to prolonged illness & sustain a quality of life.<br/>
											“Every Life Matters”
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default SJCta;