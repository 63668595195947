import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import HomeFact from '../Home/Home/HomeFact/HomeFact';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import AboutArea from './AboutArea/AboutArea';
import SJTeams from "../SJ-LungCare/SJTeams";
import SJTestimonials from "../SJ-LungCare/SJTestimonials";
import SJFooter from "../SJ-LungCare/SJFooter";

const AboutUs = () => {
   return (
      <>
         <HomeHeader/>
         <CommonPageHeader title="About SJ Lung Care" subtitle="About" />
         <AboutArea/>
         <SJTeams/>
         <HomeFact/>
         <SJTestimonials/>
         <SJFooter/>
      </>
   );
};

export default AboutUs;