import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import DoctorsTwoTeamArea from './DoctorsTwoTeamArea/DoctorsTwoTeamArea';
import SJFooter from "../../SJ-LungCare/SJFooter";

const DoctorsTwo = () => {
    return (
        <>
            <HomeHeader/>
            <CommonPageHeader title="Doctors" subtitle="Doctor" />
            <DoctorsTwoTeamArea/>
            <SJFooter/>
        </>
    );
};

export default DoctorsTwo;