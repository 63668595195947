import React from 'react';

const SingleMission = ({icon,border}) => {

   return (
       <>
          <div className={border ? `mv-single-list d-flex ${border}` : "mv-single-list d-flex"}>
             <div className="mv-icon">
                <img src={`img/icon/mv-icon-${icon}.png`} alt="#" />
             </div>
             <div className="mv-text">
                <p>It enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.</p>
             </div>
          </div>
       </>
   );
};

const SJMission = () => {

   return (
      <>
         <section className="about-area gray-bg pt-115 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-10 col-md-12">
                     <div className="about-title mb-20">
                        <h5 className="pink-color">Our Mission & Vision</h5>
                        <h1>Keep Going With Your Passion.</h1>
                     </div>
                     <div className="about-text mission-about-text">
                        <p>At SJ Lung Care Clinic, our journey began with a simple yet profound mission — to improve lives through advanced respiratory care. Over the years, we have grown into a trusted healthcare partner, offering specialized services for a spectrum of pulmonary conditions.</p><br/>
                     </div>
                     {/*<div className="mission-vision-list pr-90">*/}
                     {/*   <SingleMission icon="1" />*/}
                     {/*   <SingleMission icon="1" border="border-0" />*/}
                     {/*</div>*/}
                  </div>
                  <div className="col-xl-6 col-lg-6 d-lg-none d-xl-block">
                     <div className="mv-right-img pos-rel mb-30">
                        <img src="img/about/mvright-img-1.jpg" alt=""/>
                     </div>
                     {/*<div className="testi-quato-icon about-icon-white d-none d-xl-block">*/}
                     {/*   <img src="img/logo/onlylogo.png" alt=""/>*/}
                     {/*</div>*/}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default SJMission;