import React, { useState, useEffect } from 'react';

const SJTreat = ({ title, description }) => {
    const [selectedService, setSelectedService] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [accordionOpen, setAccordionOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const openPopup = (index) => {
        setSelectedService(services[index]);
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    const services = [
        {
            title: 'ALL Breathing Problems',
            description: 'We treat From common issues like asthma and allergies to more severe conditions such as chronic obstructive pulmonary disease (COPD) and respiratory infections.',
        },
        {
            title: 'Cough, Cold & Fever',
            description: 'We treat Cough, Cold & Fever a combination of symptoms that often signal an upper respiratory tract infection or viral illness, including a persistent cough, nasal congestion or runny nose, sneezing, sore throat, body aches, and an elevated body temperature, commonly known as fever. These conditions are frequently caused by viruses like the common cold or influenza.',
        },
        // {
        //    title: 'Lung Decortication',
        //    description: 'Description for Lung Decortication.',
        //    image: 'img/logo/bronchoscopy.jpg'
        // },
        {
            title: 'Post Covid Care',
            description: "Post-COVID care encompasses a comprehensive strategy for individuals recovering from a COVID-19 infection. It involves medical follow-ups, respiratory rehabilitation, and mental health support to address lingering symptoms and emotional challenges. Nutritional guidance, hydration, and a gradual return to physical activity contribute to overall recovery. This personalized approach aims to promote well-being after the acute phase of the illness.",
        },
        {
            title: 'Asthma & Allergy',
            description: "It's important for individuals with asthma or allergies to work closely with healthcare providers to develop a comprehensive management plan tailored to their specific needs. This plan may include both preventive measures and responsive strategies to address symptoms and improve overall respiratory health."
        },
        {
            title: 'Sleep & Snoring disorders',
            description: 'Sleep disorders encompass conditions like insomnia, sleep apnea, and restless legs syndrome, disrupting normal sleep patterns and affecting overall well-being. Snoring, a common symptom of sleep apnea, involves the vibration of respiratory structures during sleep and can lead to fragmented sleep, potentially indicating an underlying sleep disorder. Both sleep disorders and persistent snoring necessitate professional evaluation to determine the specific causes and implement tailored interventions for improved sleep quality and overall health.',
        },
        {
            title: 'Tuberculosis(TB)',
            description: 'It is a contagious bacterial infection caused by Mycobacterium tuberculosis. It primarily affects the lungs but can also impact other parts of the body. TB spreads through the air when an infected person coughs or sneezes, and it remains a significant global health concern. Common symptoms include persistent cough, weight loss, fever, and night sweats. TB is treatable with antibiotics, but medication adherence is crucial to prevent the development of drug-resistant strains. Public health measures, including vaccination and early detection, are essential for controlling the spread of tuberculosis.',
        },
        {
            title: 'Smoking Cessation',
            description: 'Smoking cessation is the process of quitting tobacco use, crucial for health improvement. It involves behavioral strategies, support systems, and sometimes medications to overcome addiction. Quitting smoking reduces the risk of heart disease, respiratory disorders, and cancer. Seeking professional support and using therapies enhance the chances of successful smoking cessation.',
        },
        {
            title: 'COPD & ILD',
            description: "Chronic Obstructive Pulmonary Disease (COPD) and Interstitial Lung Disease (ILD) are both respiratory conditions, but they differ in their underlying causes and effects. COPD, often caused by smoking, is characterized by airflow limitation, leading to conditions like chronic bronchitis and emphysema. On the other hand, ILD involves inflammation and scarring of the lung tissue, affecting the interstitium. Both conditions can result in breathing difficulties, but COPD primarily impacts airways, while ILD affects the lung's supportive tissue. Proper diagnosis and management are crucial for addressing the unique challenges posed by each condition.",
            // image: 'img/logo/bronchoscopy.jpg'
        },
        {
            title: 'Pulmonary Hypertension',
            description: "Pulmonary hypertension is a condition marked by elevated blood pressure in the lungs' arteries, restricting blood flow. Symptoms include shortness of breath, chest pain, and fatigue. Linked to heart and lung diseases, early diagnosis and management are crucial for effective treatment. Interventions may include medications, lifestyle adjustments, and, in certain instances, surgical procedures.",
            // image: 'img/logo/bronchoscopy.jpg'
        },
        {
            title: 'Pleural Effusion',
            description: "Pleural effusion involves an abnormal buildup of fluid in the pleural cavity, impacting lung function. Common causes include infections, heart failure, and inflammatory conditions. Diagnosis includes imaging and fluid analysis through thoracentesis. Treatment focuses on addressing the underlying cause and may involve drainage procedures and management of the primary condition.",
            // image: 'img/logo/bronchoscopy.jpg'
        },
        {
            title: 'Lung  Cancer',
            description: "Lung cancer is a malignancy marked by uncontrolled cell growth in the lungs, commonly linked to smoking and environmental exposures. Symptoms include persistent cough, chest pain, weight loss, and breathlessness. Early detection through screenings is vital for effective treatment, involving surgery, chemotherapy, and radiation therapy. Despite advancements, prevention, and early intervention are crucial in addressing the significant global impact of lung cancer.",
            // image: 'img/logo/bronchoscopy.jpg'
        },
    ];

    return (
        <>
            <section className="servcies-area gray-bg pt-100 ">
                <div className="container">
                    <div className="row">
                        <div className="section-title  pos-rel mb-75">
                            <div className="section-text pos-rel">
                                <h1>Diseases We Treat</h1>
                            </div>
                            <div className="section-line pos-rel">
                                <img src="img/shape/section-title-line.png" alt="" />
                            </div>
                        </div>
                        {isMobile && (
                            <div className="col-12">
                                <button
                                    className={`accordion-button main-accordion-button ${accordionOpen ? 'active' : ''}`}
                                    type="button"
                                    onClick={toggleAccordion}
                                    aria-expanded={accordionOpen}
                                    >
                                    Click Here
                                </button>
                                {accordionOpen && (
                                    <div className="accordion-details">
                                        {services.map((service, index) => (
                                            <div key={index} className="col-lg-4 col-md-4">
                                                <ul>
                                                    <li>
                                                        <div
                                                            className="mb-4 cursor-pointer treat text-black  p-3 rounded-md shadow-lg transition duration-300 ease-in-out"
                                                            onClick={() => openPopup(index)}
                                                        >
                                                            <h4>{service.title}</h4>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        {!isMobile && (
                            <>
                                {services.map((service, index) => (
                                    <div key={index} className="col-lg-4 col-md-4">
                                        <ul>
                                            <li>
                                                <div
                                                    className="mb-4 cursor-pointer treat text-black  p-3 rounded-md shadow-lg transition duration-300 ease-in-out"
                                                    onClick={() => openPopup(index)}
                                                >
                                                    <h4>{service.title}</h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
                {isPopupOpen && (
                    <div className="popup">
                        <div className="popup-content">
                            <h2>{selectedService.title}</h2>
                            <p>{selectedService.description}</p>
                            <button onClick={closePopup}>Close</button>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
};

export default SJTreat;
