import React from 'react';

const ServicesTwoAbout = () => {
    return (
        <>
            <section className="about-area pt-120 pb-90">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel align-items-lg-ends">
                                    <h5>health care facility</h5>
                                    {/*<h1> Embrace a new era of lung care that prioritizes your health, convenience, and peace of mind.</h1>*/}
                                    <div className="align-items-center">
                                        <h6>"Awareness leads to Precautions, Preparations & ultimately Education”</h6>
                                        <p >
                                            Major part of respiratory problems is not knowing enough about it.
                                            We are a group of passionate people lead by Dr Bhavya Shivalingaiah who have deep interest, learning & experience in Respiratory issues. We focus a lot, to “Provide awareness and Educate the patients & families about their issue” , so that they can manage themselves much better, have minimal sufferings due to prolonged illness & sustain a quality of life.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>At SJ Lung Care Clinic, we are dedicated to providing comprehensive and compassionate respiratory care. Our team of highly skilled and experienced pulmonologists is committed to delivering personalized services to meet the unique needs of each patient. From diagnosis to treatment and ongoing management, we strive to ensure the best possible outcomes for respiratory health.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesTwoAbout;