import React from 'react';

const ContactMap = () => {
    return (
        <>
            <section className="map-area">

                    <div style={{ width: '100%' }}>
                        <iframe
                            width="100%"
                            height="600"
                            allowFullScreen=""
                            loading="lazy"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8723471158883!2d77.507993!3d13.051143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d31cbc66f8d%3A0xda2be4b3b965d822!2sSJ%20The%20Lung%20Care%28%20Pulmonologist%2C%20Allergy%20and%20Asthma%20specialist%2C%20sleep%20medicine%2C%20Bronchoscopy%2C%20Thoracoscopy%2C%20cough%20treatment%29!5e0!3m2!1sen!2sin!4v1639823564565!5m2!1sen!2sin&z=20"
                        ></iframe>
                    </div>

            </section>
        </>
    );
};

export default ContactMap;