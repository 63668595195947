import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../../hooks/useGlobalContext';

const HomeHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="top-bar d-none d-md-block">
               <div className="container">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-7 offset-md-1">
                        <div className="header-info p-4">
                           <span><i className="fas fa-phone"></i>+91 7975242178</span>
                           <span><i className="fas fa-envelope"></i>thelungcare@gmail.com</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className={stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"}>
               <div className="container menu_wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                        <div className="logo logo-circle pos-rel">
                           <Link to="/"><img src="img/logo/onlylogowt.png" alt="" /></Link>
                        </div>
                     </div>
                     <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                        <div className="header-right f-right">
                           <div className="header-social-icons f-right d-none d-xl-block">
                              <ul>
                                 <li><a href="https://m.facebook.com/profile.php/?id=100064073920094&name=xhp_nt__fb__action__open_user"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="http://www.youtube.com/@sjthelungcare3744"><i className="fab fa-youtube"></i></a></li>
                                 {/*<li><a href="#"><i className="fab fa-linkedin"></i></a></li>*/}
                              </ul>
                           </div>
                        </div>
                        <div className="header__menu f-right">
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to="/">Home</Link></li>
                                 <li><Link to="/about">About Us</Link></li>
                                 <li><Link to="/services">Services</Link></li>
                                 <li><Link to="/doctors">Doctors</Link></li>
                              </ul>
                           </nav>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeHeader;