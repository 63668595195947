import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';
import Collapsible from 'react-collapsible';

const Sidebar = ({ show, handleClose }) => {
   const Home = <NavLink to="/">Home</NavLink>
   const About = <NavLink to="/about">About </NavLink>
   const Services = <NavLink to="/services">Services </NavLink>
   const Doctors = <NavLink to="/doctors">Doctors</NavLink>
   // const News = <NavLink to="/blogSidebar">News</NavLink>
   // const Pages = <a href="#">Pages</a>
   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
               </Offcanvas.Header>
               <Offcanvas.Body>
                  <diV className="sidebar_component">
                  <Collapsible trigger={Home} triggerTagName="div">
                     <ul className="sidebar_sub_menu text-white mt-3">
                        {/*<li><NavLink to="/">Home style 1</NavLink></li>*/}
                        {/*<li><NavLink to="/homeTwo">Home style 2</NavLink></li>*/}
                        {/*<li><NavLink to="/homeThree">Home style 3</NavLink></li>*/}
                        {/*<li><NavLink to="/homeFour">Home style 4</NavLink></li>*/}
                        {/*<li><NavLink to="/homeFive">Home style 5</NavLink></li>*/}
                     </ul>
                  </Collapsible>
                  </diV>

                  <diV className="sidebar_component">
                  <Collapsible trigger={About} triggerTagName="div">
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/about">About Us</NavLink></li>
                        {/*<li><NavLink to="/servicesTwo">Services 02</NavLink></li>*/}
                        {/*<li><NavLink to="/servicesDetails">Services Details</NavLink></li>*/}
                     </ul>
                  </Collapsible>
                  </diV>

                  <diV className="sidebar_component">
                  <Collapsible trigger={Services} triggerTagName="div">
                     <ul className="sidebar_sub_menu text-white mt-3">
                     {/*   <li><NavLink to="/doctors">Doctors 01</NavLink></li>*/}
                     {/*   <li><NavLink to="/doctorsTwo">Doctors 02</NavLink></li>*/}
                     {/*   <li><NavLink to="/doctorDetails">Doctors Details</NavLink></li>*/}
                     </ul>
                  </Collapsible>
                  </diV>

                  <diV className="sidebar_component">
                  <Collapsible trigger={Doctors} triggerTagName="div">
                     <ul className="sidebar_sub_menu text-white mt-3">
                     {/*   <li><NavLink to="/shop">Shop Page</NavLink></li>*/}
                     {/*   <li><NavLink to="/shopDetails">Shop Details</NavLink></li>*/}
                     {/*   <li><NavLink to="/shoppingCart">Shopping Cart</NavLink></li>*/}
                     {/*   <li><NavLink to="/checkout">Checkout</NavLink></li>*/}
                     {/*   <li><NavLink to="/wishList">Wishlist</NavLink></li>*/}
                     {/*   <li><NavLink to="/login">Login</NavLink></li>*/}
                     {/*   <li><NavLink to="/register">Register</NavLink></li>*/}
                     </ul>
                  </Collapsible>
                  </diV>

                  {/*<Collapsible trigger={Shop} triggerTagName="div"*/}
                  {/*             triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>*/}
                  {/*   <ul className="sidebar_sub_menu text-white mt-3">*/}
                  {/*      <li><NavLink to="/shop">Shop Page</NavLink></li>*/}
                  {/*      <li><NavLink to="/shopDetails">Shop Details</NavLink></li>*/}
                  {/*      <li><NavLink to="/shoppingCart">Shopping Cart</NavLink></li>*/}
                  {/*      <li><NavLink to="/checkout">Checkout</NavLink></li>*/}
                  {/*      <li><NavLink to="/wishList">Wishlist</NavLink></li>*/}
                  {/*      <li><NavLink to="/login">Login</NavLink></li>*/}
                  {/*      <li><NavLink to="/register">Register</NavLink></li>*/}
                  {/*   </ul>*/}
                  {/*</Collapsible>*/}
                  {/*<Collapsible trigger={News} triggerTagName="div"*/}
                  {/*   triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>*/}
                  {/*   <ul className="sidebar_sub_menu text-white mt-3">*/}
                  {/*      <li><NavLink to="/blogs">Blog Right Sidebar</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogLeftSideBar">Blog Left Sidebar</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogNoSideBar">Blog No Sidebar</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogTwoColumn">Blog 2 Column</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogTwoMasonry">Blog 2 Col Masonry</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogThreeColumn">Blog 3 Column</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogThreeColMasonry">Blog 3 Col Masonry</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogDetails">Blog Details</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogDetailsLeftSidebar">Details Left Sidebar</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogDetailsAudio">Details Audio</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogDetailsVideo">Details Video</NavLink></li>*/}
                  {/*      <li><NavLink to="/blogDetailsGallery">Details Gallery</NavLink></li>*/}
                  {/*   </ul>*/}
                  {/*</Collapsible>*/}

                  {/*<Collapsible trigger={Pages} triggerTagName="div"*/}
                  {/*   triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>*/}
                  {/*   <ul className="sidebar_sub_menu text-white mt-3">*/}
                  {/*      <li><NavLink to="/about">About</NavLink></li>*/}
                  {/*      <li><NavLink to="/appoinment">Appoinment</NavLink></li>*/}
                  {/*      <li><NavLink to="/portfolioTwoColumn">Portfolio 2 column</NavLink></li>*/}
                  {/*      <li><NavLink to="/portfolioThreeColumn">Portfolio 3 column</NavLink></li>*/}
                  {/*      <li><NavLink to="/portfolioSlider">Portfolio Slider</NavLink></li>*/}
                  {/*      <li><NavLink to="/contact">Contact</NavLink></li>*/}
                  {/*      <li><NavLink to="/notMatch">404 page</NavLink></li>*/}
                  {/*   </ul>*/}
                  {/*</Collapsible>*/}
                  <div className="row">
                     <div className="col-6 d-lg-none pl-0 btn-a-m"> {/* Hide on large screens */}
                           <Link to="/contact" className="mt-10 primary_btn_m btn-icon-m btn-icon-m-green">
                              <span>+</span>Contact Us
                           </Link>
                     </div>
                     <div className="col-6 d-lg-none"> {/* Hide on large screens */}
                        <a href="tel:+7975242178" className="mt-10 primary_btn_m btn-icon-m btn-icon-m-green">Click to Call</a>
                     </div>
                  </div>

               </Offcanvas.Body>
            </Offcanvas>

         </div>
      </>
   );
};

export default Sidebar;