import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const ServicesTwoSingleItem = ({image,title,description}) => {


    const [isPopupOpen, setPopupOpen] = useState(false);

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="service-box-3 mb-30 text-center">
                    <div className="service-thumb">
                        <Link to="">
                            <img src={`img/blog/news-thumb-${image}.jpg`} alt="" />
                        </Link>
                    </div>
                    <div className="service-content-box">
                        <div className="service-content">
                            <h3>{title}</h3>
                            <p className={isPopupOpen ? 'hidden' : ''}>{description}</p>
                            {!isPopupOpen && (
                                <Link to="#" className="service-link" onClick={openPopup}>
                                    Read More
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-content">
                        <h2>{title}</h2>
                        <p>{description}</p>
                        <button onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ServicesTwoSingleItem;