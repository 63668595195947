import React from 'react';
import Slider from "react-slick";

const Testimonial = ({image, title, content, author}) => {
    return (
        <>
            <div className="col-xl-12 home_two_testimonial_wrapper">
                <div className="testi-box-2">
                    <div className="test-rating-inner d-flex justify-content-between mb-30 align-items-center pr-15">
                        <div className="testi-quato-icon testi-quato-icon-green m-0">
                            <img src="img/testimonials/testi-quato-icon.png" alt=""/>
                        </div>
                        <div className="testi-rating-list">
                            <ul>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div className="testi-content-2">
                        <h3>{title}</h3>
                        <p>{content}</p>
                    </div>
                    <div className="testi-author d-flex align-items-center mt-30">
                        {/*<div className="testi-author-icon-2">*/}
                        {/*   <img src={`img/icon/tesit-author-icon-${image}.png`} alt=""/>*/}
                        {/*</div>*/}
                        <div className="testi-author-desination-2">
                            <h4>{author}</h4>
                            {/*<span>Founder At <span className="f-500 green-color">MinimalDesign</span></span>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const SJTestimonials = () => {
    const settings = {
        autoplay: true,
        autoplaySpeed: 3800,
        dots: false,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
        ]
    };

    const testimonialsData = [
        {
            image: "1",
            title: "Visited Dr. Bhavya Shivalingaiah (Pulmonologist) For Lower/Upper Respiratory Tract Infection Treatment",
            content: (
                <>

                    <p>
                        I recommend the doctor Happy with: Treatment satisfactionDoctor friendlinessExplanation of the
                        health issue
                    </p>
                    <p>
                        Very empathetic and makes patients feel cared for. She listens to all patient problems patiently
                        and provides one of the best treatments.
                    </p>
                    <p>
                        The hospital staff are also very supportive and respond in time to meet patient needs.
                    </p>

                </>
            ),
            author: "Veena"
        },
        {
            image: "2",
            title: "Visited Dr. Bhavya Shivalingaiah (Pulmonologist) For Lung Infections Treatment",
            content: (
                <>
                    <p>
                        I recommend the doctor Happy with: Doctor friendlinessExplanation of the health issueTreatment
                        satisfactionValue for moneyWait time
                    </p>
                    <p>
                        Doctor was very caring and responsive in explaining about the illness. Moreover, she was
                        actively listening to our problems and taking forward in decision making.
                    </p>
                </>
            ),
            author: "Ramesh P"
        },
        {
            image: "1",
            title: "Visited Dr. Bhavya Shivalingaiah (Pulmonologist)",
            content: (
                <>
                    <p>
                        I recommend the doctor Good atmosphere, friendly Dr. And staff. Reqd testing under one roof.
                        Saves more time. Good Explanation.
                    </p>
                </>
            ),
            author: "Ramachandra"
        },
        {
            image: "1",
            title: "Visited Dr. Bhavya Shivalingaiah (Pulmonologist) For Shortness of BreathAsthmaBreathing Problems",
            content: (
                <>
                    <p>
                        I recommend the doctor Happy with: Doctor friendlinessExplanation of the health issueWait time
                    </p>
                    <p>
                        Dr. Bhavya was very helpful in identifying the basic issue. We have started medications. Will be
                        able to conclude on next steps by the next visit.
                    </p>
                </>
            ),
            author: "Anitha Samuel"
        },
        {
            image: "1",
            title: "Visited Dr. Bhavya Shivalingaiah (Pulmonologist) For Breathing ProblemsShortness of Breath",
            content: (
                <>
                    <p>
                        I recommend the doctor Happy with: Doctor friendlinessExplanation of the health issueTreatment
                        satisfactionValue for moneyWait time
                    </p>
                    <p>
                        The doctor is clear and comprehensive with her treatment explanation. The condition I visited
                        her for has improved significantly. Grateful for the much-needed relief! Thank you :)
                    </p>
                </>
            ),
            author: "Afreen"
        },
    ];

    return (
        <>
            <div className="testimonials-area pt-115 pb-110 md:pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
                            <div className="section-title text-center pos-rel mb-70">
                                <div className="section-icon">
                                    <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text pos-rel">
                                    {/*<h5>Testimonials</h5>*/}
                                    <h1>Patient Stories for <br/>SJ The Lung Care</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Slider className='testimonials-activation' {...settings}>
                        {testimonialsData.map((testimonial, index) => (
                            <Testimonial key={index} {...testimonial} />
                        ))}
                    </Slider>


                    {/*<div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1 col-md-10 offset-md-1">*/}
                    {/*    <div className="section-title text-center pos-rel mb-70">*/}
                    {/*        <div className="section-icon">*/}
                    {/*            <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className="section-text pos-rel">*/}
                    {/*            /!*<h5>Testimonials</h5>*!/*/}
                    {/*            <h1>Explore the real experiences <br/>and positive stories </h1>*/}
                    {/*        </div>*/}
                    {/*        <div className="section-line pos-rel">*/}
                    {/*            <img src="img/shape/section-title-line.png" alt=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="testimonials">

                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/VwkOrE3R6aM?si=thljlGgb8slZHV7-"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/kHkzSEv62-w?si=oeApMaNL4ciIrkEG"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/gSRmW6yJpmM?si=32n5ryzs3poomtic"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/YgrSwtjzLFQ?si=n9-x4RBx_92y2bs7"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>


                </div>
            </div>
        </>
    );
};

export default SJTestimonials;
