import React from 'react';

const CommonSectionArea = ({area_header}) => {
   return (
      <>
         <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5">
                     {area_header}
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h3>About Us</h3>
                           {/*<h1>Short Story About SJ Lung Care Clinic.</h1>*/}
                        </div>
                        <div className="about-text mb-50">
                           <p>We are the only Full scale Respiratory Center in Bangalore. <br/>25 Oxygen supportive beds, Fully equipped ICU, Facility for all types respiratory interventions <br/>Round the clock Pulmonologists specialized in interventions, Respiratory technicians & other supporting staff who are well versed and well groomed in handling respiratory emergencies.</p>
                        </div>
                        <div className="our-destination">
                           <div className="single-item mb-30">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-1.png" alt=""/>
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Mission</h3>
                                 <p>We envision a future where respiratory health is optimized, and individuals lead fulfilling lives unburdened by pulmonary concerns.</p>
                              </div>
                           </div>
                           <div className="single-item">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-2.png" alt=""/>
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Vision</h3>
                                 <p>To achieve our vision, we are dedicated to providing compassionate, evidence-based care that prioritizes the well-being of our patients.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;