import React from 'react';
import HomeTwoNavBar from "../HomeTwo/HomeTwoNavBar/HomeTwoNavBar";
import SJHeroSection from "./SJHeroSection";
import SJAboutArea from "./SJAboutArea";
import SJServices from "./SJServices";
import SJTeams from "./SJTeams";
import SJPhotoGallery from "./SJPhotoGallery";
import SJMission from "./SJMission";
import SJFact from "./SJFact";
import SJCta from "./SJCta";
import SJFooter from "./SJFooter";
import SJTestimonials from "./SJTestimonials";
import ServicesTwoAbout from "../Services/ServicesTwo/ServicesTwoAbout/ServicesTwoAbout";
import ServicesTwoArea from "../Services/ServicesTwo/ServicesTwoArea/ServicesTwoArea";
import SJTreact from "./SJTreat";


const SJHome = () => {
    return (
        <>
            <HomeTwoNavBar/>
            <SJHeroSection/>
            <ServicesTwoArea/>
            <SJAboutArea/>
            <SJServices/>
            <SJTreact/>
            <SJMission/>
            <SJCta/>
            <SJTeams/>
            <SJFact/>
            <SJTestimonials/>
            <SJPhotoGallery/>
            <SJFooter/>
        </>
    );
};

export default SJHome;