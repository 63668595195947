import React from 'react';
import Slider from "react-slick";
import useGlobalContext from "../../hooks/useGlobalContext";

const PhotoGallery = ({ image, title }) => {
   return (
       <>
          <div className="">
             <div className="gallery-box pos-rel">
                {/*<img src={`img/home4/gallery/gallery__thum${image}.jpg`} alt="" />*/}
                <img src={`img/sj/IMG-20231201-${image}.jpg`} alt="" />
                {/*<div className="gallery-content">*/}
                {/*   <h2 className="f-600 theme-color">{title}</h2>*/}
                {/*</div>*/}
             </div>
          </div>
       </>
   );
};

const SJPhotoGallery = () => {
   const { SlickArrowLeft, SlickArrowRight } = useGlobalContext();
   // setting
   const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 300,
      prevArrow: <SlickArrowLeft />,
      nextArrow: <SlickArrowRight />,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false,
            }
         }
      ]

   };
   return (
      <>
         <section className="photogallery pt-130 pb-180">
            <div className="container">
               <div className="row">
                  <div className="col-lg-5">
                     <div className="about-title mb-65">
                        <h5 className="pink-color">Photo Gallery</h5>
                        <h1>Let’s See Latest Photo Album</h1>
                     </div>
                  </div>
               </div>

               <Slider className='h4gallery-active h4_services_slider h4_photogallery' {...settings}>
                  <PhotoGallery image="WA0008" title="Our Hospitality" />
                  <PhotoGallery image="WA0009" title="Trusted Partner" />
                  <PhotoGallery image="WA0010" title="Trusted Partner" />
                  <PhotoGallery image="WA0011" title="Trusted Partner" />
                  <PhotoGallery image="WA0012" title="Trusted Partner" />
                  <PhotoGallery image="WA0013" title="Trusted Partner" />
                  <PhotoGallery image="WA0014" title="Trusted Partner" />
                  <PhotoGallery image="WA0015" title="Trusted Partner" />
                  <PhotoGallery image="WA0016" title="Trusted Partner" />
                  <PhotoGallery image="WA0017" title="Trusted Partner" />
                  <PhotoGallery image="WA0018" title="Trusted Partner" />
                  <PhotoGallery image="WA0019" title="Trusted Partner" />
                  <PhotoGallery image="WA0020" title="Trusted Partner" />
                  <PhotoGallery image="WA0021" title="Trusted Partner" />
                  <PhotoGallery image="WA0022" title="Trusted Partner" />
                  <PhotoGallery image="WA0023" title="Trusted Partner" />
                  <PhotoGallery image="WA0024" title="Trusted Partner" />
                  <PhotoGallery image="WA0025" title="Trusted Partner" />
                  <PhotoGallery image="WA0026" title="Trusted Partner" />
                  <PhotoGallery image="WA0027" title="Trusted Partner" />
                  <PhotoGallery image="WA0028" title="Trusted Partner" />
                  <PhotoGallery image="WA0029" title="Trusted Partner" />
                  <PhotoGallery image="WA0030" title="Trusted Partner" />
                  <PhotoGallery image="WA0031" title="Trusted Partner" />
                  <PhotoGallery image="WA0032" title="Trusted Partner" />
                  <PhotoGallery image="WA0033" title="Trusted Partner" />
                  <PhotoGallery image="WA0034" title="Trusted Partner" />
                  <PhotoGallery image="WA0035" title="Trusted Partner" />
                  <PhotoGallery image="WA0036" title="Trusted Partner" />
                  <PhotoGallery image="WA0037" title="Trusted Partner" />
               </Slider>

            </div>
         </section>
      </>
   );
};

export default SJPhotoGallery;